/**
 * @file Page informations and useful tools
*/

const avoirURLBase = () => {
    _log("avoirURLBase");
    return window.location.origin + (window.location.host == "localhost" ? '/c7_back' : '');
};

const avoirRelativeURL = () => {
    _log("avoirRelativeURL");
    return window.location.pathname;
};

/**
 * 
 * @param {string} _url 
 * @returns boolean|void
*/
const redirectTo = (_url) => {
    if(typeof _url != "string") {
        return false;
    }
    if(_url.length == 0) {
        return false;
    }
    window.location.href = _url;
}

const get_url_params = () => {
    if(typeof window != undefined) {
        return {
            pathname: get_url_pathname(),
            search: get_url_search(true),
            hash: get_url_hash(),
        };
    }
    else {
        return {};
    }
}

// protocol + :// + host + pathname + search + hash
// protocol + :// + hostname + port + pathname + search + hash

const get_url_port = () => {if(typeof window != undefined) {return window.location.port;}else {return {};}};
const get_url_pathname = () => {if(typeof window != undefined) {return window.location.pathname;}else {return {};}};
const get_url_search = (as_array = true) => {
    if(typeof window != undefined) {
        return as_array ? Object.fromEntries(window.location.search.replace('?', '').split('&').map(elem => elem.split('='))) : window.location.search;
    }
    else {return {};}
};
const get_url_hash = () => {if(typeof window != undefined) {return window.location.hash;}else {return {};}};

const url_from_params = (_params) => {
    if(!isObject(_params)) {
        return '';
    }
    let _retour = window.location.pathname + (Object.keys(_params).length > 0 ? '?' : '');
    Object.keys(_params).forEach((_param, _i) => {
        if(_params[_param]) {
            _retour += `${_i > 0 ? '&' : ''}${_param}=${_params[_param]}`;
        }
    })
    return _retour;
}

const indexElement = (_elem) => {
    if(isElement(_elem)) {
        _parent = _elem?.parentElement;
        if(isElement(_parent)) {
            let _index = 0;
            while(_parent.children[_index]) {
                if(_parent.children[_index] === _elem) {
                    return _index;
                }
                _index++;
            }
        }
        else {
            return -1;
        }
    }
    return -1;
}

const randomString = function(length = 10, letters = true, numbers = true)
{
    let characters = "";
    if(letters == true) {
        characters += "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
    }
    if(numbers == true) {
        characters += "0123456789"
    }
    let charactersLength = characters.length;
    let randomString = '';
    for (let i = 0; i < length; i++) {
        randomString += characters[(Math.random() * charactersLength - 1).toFixed(0)];
    }
    return randomString;
}

const randColor = (rgba = null) => {
    let _1 = Math.floor(Math.random() * 255);
    let _2 = Math.floor(Math.random() * 255);
    let _3 = Math.floor(Math.random() * 255);
    return `#${[_1, _2, _3].map(num => num > 15 ? num.toString(16) : `0${num.toString(16)}`).join('')}${(rgba) ? (rgba * 2.55).toFixed(0).toString(16) : ''}`;
}

const preventDefault = (e) => e.preventDefault();

const strToDom = function(str) {
    try {
        return document.createRange().createContextualFragment(str);
    }
    catch(_err) {
        _trace(_err);
        _error(_err);
        return null;
    }
}

/**
 * @param {string} html representing a single element
 * @return {Element}
*/
const htmlToElement = function(html) {
    var template = document.createElement('template');
    html = html.trim(); // Never return a text node of whitespace as the result
    template?.append(strToDom(html));
    return template.content.firstChild;
}

/**
 * @param {string} html representing any number of sibling elements
 * @return {NodeList} 
*/
const htmlToElements = function(html) {
    var template = document.createElement('template');
    template?.append(strToDom(html));
    return template.content.childNodes;
}

const getOS = function() {
    if(typeof navigator != "undefined") {
        if (navigator.appVersion.indexOf("Win") != -1) {
            return "Windows";
        }
        if (navigator.appVersion.indexOf("Mac") != -1) {
            return "MacOS";
        }
        if (navigator.appVersion.indexOf("Linux") != -1) {
            return "Linux";
        }
    }
    return undefined;
}

const detectOS = getOS() ?? "Unknown OS";

const scrollX = function(_dx = null, _elems = null) {
    if(_dx == null || _dx == 0 || _elems == null || !Array.isArray(_elems) || _elems.length == 0) {
        return false;
    }
    _elems.forEach(_elem => {
        _elem.scrollLeft += _dx;
    });
}

const scrollY = function(_dy = null, _elems = null) {
    if(_dy == null || _dy == 0 || _elems == null || !Array.isArray(_elems) || _elems.length == 0) {
        return false;
    }
    _elems.forEach(_elem => {
        _elem.scrollTop += _dy;
    });
}

const insertFirst = function(newNode, parent) {
    // console.log("insertFirst");
    // console.log(newNode);
    // console.log(parent);
    if((newNode instanceof Node || newNode instanceof Element) && parent instanceof Element) {
        if(parent.childElementCount == 0) {insertLast(newNode, parent);}
        else {
            insertBefore(newNode, parent.children[0]);
        }
    }
}
const insertLast = function(newNode, parent) {
    // console.log("insertLast");
    // console.log(newNode);
    // console.log(parent);
    if((newNode instanceof Node || newNode instanceof Element) && parent instanceof Element) {
        parent?.appendChild(newNode);
    }
}

const insertAfter = function(newNode, existingNode) {
    // console.log("insertAfter");
    // console.log(newNode);
    // console.log(existingNode);
    if((newNode instanceof Node || newNode instanceof Element) && existingNode instanceof Element) {
        existingNode?.parentElement.insertBefore(newNode, existingNode?.nextSibling);
    }
}

const insertBefore = function(newNode, existingNode) {
    // console.log("insertBefore");
    // console.log(newNode);
    // console.log(existingNode);
    if((newNode instanceof Node || newNode instanceof Element) && existingNode instanceof Element) {
        existingNode?.parentElement.insertBefore(newNode, existingNode);
    }
}

const filter_object = function(obj, predicate) {
    return Object.keys(obj)
        .filter( key => predicate(obj[key]) )
        .reduce( (res, key) => (res[key] = obj[key], res), {} );
}

const resolveEntityPath = function(_entity = null, _path = null) {
    // console.log("resolveEntityPath");
    if(_entity == null || !isString(_path)) {return null;}
    let index = 0;
    let path = _path.split('__');
    let retour = _entity;
    while(index < path.length && retour != null) {
        retour = retour[path[index++]] ?? null;
    }
    return retour;
}

const loadingGIF = function(_params = {}, _from_remote = false) {
    if(_from_remote == false) {
        return `
            <div ${_params.id && `id="${_params.id}"` || ""} class="loading-gif ${_params.classes && _params.classes.join(' ') || ""}">
                <div class="loader-circle"></div>
                <div class="loader-bar"></div>
            </div>
        `
    };
}

/**
 * @description Remonte les balises jusqu'au critère recherché
*/
const resolveUpUntil = function(_elem = null, _critere = null, _type = "class")
{
    if(!isElement(_elem)) {return null;}
    if(!isString(_critere) && (_type == "class" || _type == "id" || _type == "name")) {return false;}

    do {
        switch(_type) {
            case "id": if(_elem.id == _critere) {return _elem;}
            case "name": if(_elem.name == _critere) {return _elem;}
            case "class":
            default:
                if(_elem?.classList.contains(_critere)) {return _elem;}
        }
        _elem = _elem?.parentElement || null;
    } while(_elem?.parentElement);
    return _elem;
}

const getFullDate = function(_timestamp = Date.now(), _need = true) {
    if(!isInt(_timestamp) && _need == false) {return false;}
    if(!isInt(_timestamp)) {_timestamp = Date.now();}
    let _str_date = `${new Date(_timestamp).getFullYear()}-`;
    _str_date += `${new Date(_timestamp).getMonth() + 1 < 10 ? '0' : ''}${new Date(_timestamp).getMonth() + 1}-`;
    _str_date += `${new Date(_timestamp).getDate() < 10 ? '0' : ''}${new Date(_timestamp).getDate()}`;
    return _str_date;
}

const getFullTime = function(_timestamp) {

}

const getFullDateTime = function(_timestamp) {

}
